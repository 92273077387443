import React from 'react'
import cx from 'classnames'
import PT from 'prop-types'
import ReactDOM from 'react-dom'
import './index.scss'

const Loading = ({className, fullscreen}) => (
  <div
    className={cx('Loading', {'Loading--fullscreen': fullscreen}, className)}
  >
    <div className="Loading__main" />
  </div>
)

Loading.propTypes = {
  className: PT.string,
  fullscreen: PT.bool,
}

Loading.defaultProps = {
  className: '',
  fullscreen: false,
}

const destroyHandlers = []

const showLoading = () => {
  const ele = document.createElement('div')
  document.body.appendChild(ele)

  ReactDOM.render(<Loading fullscreen />, ele)

  destroyHandlers.push(() => {
    ReactDOM.unmountComponentAtNode(ele)
    document.body.removeChild(ele)
  })
}

const hideLoading = () => {
  let handler = destroyHandlers.shift()
  while (handler) {
    handler()
    handler = destroyHandlers.shift()
  }
}

export {Loading as default, showLoading, hideLoading}
