import React, {Component} from 'react'
import {Picker, List} from 'antd-mobile'
import {connect} from 'react-redux'
import PT from 'prop-types'
import REGIONS from './constants'
import './index.scss'

class RegionSelector extends Component {
  onOk = value => {
    this.props.onChange(value[0])
  }

  render() {
    const {value} = this.props
    return (
      <Picker data={REGIONS} onOk={this.onOk} cols={1}>
        <List.Item arrow="empty" className="RegionSelector__text">
          +{value}
        </List.Item>
      </Picker>
    )
  }
}

RegionSelector.propTypes = {
  onChange: PT.func.isRequired,
  value: PT.number.isRequired,
}

export default connect()(RegionSelector)
