import createActionTypes from '../actions/createActionTypes'

const LOGIN = createActionTypes('LOGIN')
const login = ({
  phone,
  regionCode,
  code,
  plat,
  thirdKind,
  openCode,
  openId,
}) => ({
  api: {
    path: '/account/login_verify_code',
    data: {
      phone,
      regionCode,
      code,
      plat,
      thirdKind,
      openCode,
      openId,
    },
  },
  types: LOGIN,
})

const LOGINWX = createActionTypes('LOGINWX')
const loginWx = ({code, kind}) => ({
  api: {
    path: '/account/login_third',
    data: {
      code,
      kind,
    },
  },
  types: LOGINWX,
})

const LOGOUT = createActionTypes('LOGOUT')
const logout = () => ({
  api: {
    path: '/account/login_sign_out',
    data: {},
  },
  types: LOGOUT,
})

const GET_VERIFY_CODE = createActionTypes('GET_VERIFY_CODE')
const getVerifyCode = ({phone, regionCode, type}) => ({
  api: {
    path: '/account/get_verify_code',
    data: {phone, regionCode, type},
  },
  types: GET_VERIFY_CODE,
})

const ACCOUNT_DEREGISTER = createActionTypes('ACCOUNT_DEREGISTER')
const accountDeregister = () => ({
  api: {
    path: '/account/deregister',
    data: {},
  },
  types: ACCOUNT_DEREGISTER,
})

export {login, loginWx, logout, getVerifyCode, accountDeregister}
