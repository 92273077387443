import once from 'lodash/once'

const appId = 'wxcf4013c78f9330d9'
const appIdOpen = 'wx3dbe8ac048413328'
const appIdWeb = 'wxae8208cead605d41'
const appId4LH = 'wx4a09bd8e35d82654'
const appId4LHWeb = 'wxcd47ad5a03738368'
const appId4SD = 'wx66e8fad30fb13c53'
const appIdOpen4SD = 'wxf707d86c22362306'
const appId4X = 'wx7e28f1dd0cd12003'
const appId4XWeb = 'wx238d6dbec6093757'
const appIdOpen4X = 'wx5c6986e6fe581d68'

// 文档 https://mp.weixin.qq.com/wiki?t=resource/res_main&id=mp1421141115
const initConfig = ({noncestr, signature, timestamp}) => {
  window.wx.config({
    debug: false,
    appId,
    timestamp,
    nonceStr: noncestr,
    signature,
    jsApiList: [
      'onMenuShareAppMessage',
      'onMenuShareTimeline',
      'updateAppMessageShareData',
      'updateTimelineShareData',
      'chooseWXPay',
      'scanQRCode',
    ],
    openTagList: ['wx-open-launch-app'],
  })
}

const initConfig4LH = ({noncestr, signature, timestamp}) => {
  window.wx.config({
    debug: false,
    appId: appId4LH,
    timestamp,
    nonceStr: noncestr,
    signature,
    jsApiList: [
      'onMenuShareAppMessage',
      'onMenuShareTimeline',
      'updateAppMessageShareData',
      'updateTimelineShareData',
      'chooseWXPay',
      'scanQRCode',
    ],
    openTagList: ['wx-open-launch-app'],
  })
}

const initConfig4SD = ({noncestr, signature, timestamp}) => {
  window.wx.config({
    debug: false,
    appId: appId4SD,
    timestamp,
    nonceStr: noncestr,
    signature,
    jsApiList: [
      'onMenuShareAppMessage',
      'onMenuShareTimeline',
      'updateAppMessageShareData',
      'updateTimelineShareData',
      'chooseWXPay',
      'scanQRCode',
    ],
    openTagList: ['wx-open-launch-app'],
  })
}

const initConfig4X = ({noncestr, signature, timestamp}) => {
  window.wx.config({
    debug: false,
    appId: appId4X,
    timestamp,
    nonceStr: noncestr,
    signature,
    jsApiList: [
      'onMenuShareAppMessage',
      'onMenuShareTimeline',
      'updateAppMessageShareData',
      'updateTimelineShareData',
      'chooseWXPay',
      'scanQRCode',
      'startRecord',
      'stopRecord',
      'playVoice',
      'stopVoice',
      'translateVoice',
    ],
    openTagList: ['wx-open-launch-app'],
  })
}

const onReady = once(
  () =>
    new Promise((resolve, reject) => {
      window.wx.ready(resolve)
      window.wx.error(reject)
    })
)

const callPay = ({timestamp, nonceStr, pkg, signType, paySign}) =>
  new Promise((resolve, reject) => {
    window.wx.chooseWXPay({
      timestamp,
      nonceStr,
      package: pkg,
      signType,
      paySign,
      success: resolve,
      fail: reject,
      cancel: reject,
    })
  })

export {
  initConfig,
  initConfig4LH,
  initConfig4SD,
  initConfig4X,
  onReady,
  callPay,
  appId,
  appIdOpen,
  appIdWeb,
  appId4LH,
  appId4LHWeb,
  appId4SD,
  appIdOpen4SD,
  appId4X,
  appId4XWeb,
  appIdOpen4X,
}
