import React, {Fragment} from 'react'
import Helmet from 'react-helmet'
import {connect} from 'react-redux'
import {Toast} from 'antd-mobile'
import RRPT from 'react-router-prop-types'
import cx from 'classnames'
import PT from 'prop-types'
import qs from 'query-string'
import Region from '../../components/RegionSelector'
import {login, loginWx, getVerifyCode} from '../../reducers/login'
import {
  appId,
  appId4LHWeb,
  appId4XWeb,
  appId4SD,
  appId4X,
  appIdWeb,
} from '../../utils/wxSDK'
import {showLoading, hideLoading} from '../../components/Loading'
import {isLH, isMobile, isPW, isSD, isWX, isX} from '../../utils/ua'
import imgShareWechat from '../../resource/images/share_icon_wechat@3x.png'
import {closeWindow, login as loginApp, openWindow} from '../../utils/pwHybrid'
import imgClose from '../../resource/images/hot_leaderboard_rule_close@3x.png'
import './index.scss'
import {isNode} from '../../utils'
import {fullUrl} from '../../utils/url'
import {setAuthInfo} from '../../utils/auth'

const MAX_ELAPSE = 60

const codeLength = 4

class Login extends React.Component {
  refCode = null
  wxCode = ''

  state = {
    phone: '',
    elapseSeconds: -1,
    code: '',
    region: 86,
  }

  componentDidMount() {
    this.getWxCode(false)
  }

  componentWillUnmount() {
    if (this.timer) {
      clearInterval(this.timer)
    }
  }

  onGetVerifyCode = async () => {
    const {phone, region} = this.state
    const {dispatch} = this.props
    const params = {
      regionCode: region,
      type: 1,
      phone: Number(phone),
    }

    showLoading()
    try {
      await dispatch(getVerifyCode(params))

      this.startCounter()
    } catch (error) {
      Toast.info('获取验证码失败，请重试')
    } finally {
      hideLoading()
    }
  }

  onInputPhone = evt => {
    const {value} = evt.target
    this.setState({
      phone: value.replace(/\D+/g, ''),
    })
  }

  onInputCode = evt => {
    const {value} = evt.target
    this.setState({
      // H5 IOS自动填充验证码两次的bug https://www.jianshu.com/p/833eb3dc6f42
      code: value.slice(0, codeLength),
    })
  }

  onTryLogin = async () => {
    const {dispatch, history, next, hostname, kind} = this.props
    showLoading()
    try {
      const {phone, code, region} = this.state
      // const code = this.refCode.value
      const queries = qs.parse(window.location.search)
      const {code: openCode = '', openId = ''} = queries || {}
      const params = {
        phone: Number(phone),
        code: String(code),
        regionCode: region,
        thirdKind: kind === 0 ? 0 : 1,
        openCode,
        openId,
      }
      await dispatch(login(params))
      if (next !== '') {
        history.replace(`${next}`)
        history.go(0)
        return
      }
      if (isSD(hostname)) {
        history.replace('/hybrid/lh/sz/home')
      } else {
        history.replace('/wallet')
      }
    } catch (err) {
      Toast.info(err || '登录失败，请重试')
    } finally {
      hideLoading()
    }
  }

  getWxCode = refresh => {
    if (isNode()) {
      return
    }
    const queries = qs.parse(window.location.search)
    const {code} = queries
    const {userAgent, hostname} = this.props
    if (!code && refresh) {
      if (ENVIRONMENT === 'development') {
        // return
      }
      if (!isWX(userAgent)) {
        // return
      }
      let aid = appId
      let aidWeb = appIdWeb
      if (isSD(hostname)) {
        aid = appId4SD
        aidWeb = appId4LHWeb
      } else if (isX(hostname)) {
        aid = appId4X
        aidWeb = appId4XWeb
      }
      if (isWX(userAgent)) {
        window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${aid}&redirect_uri=${encodeURIComponent(
          window.location
        )}&response_type=code&scope=snsapi_userinfo&state=a#wechat_redirect`
      } else if (!isMobile(userAgent)) {
        if (isSD(this.props.hostname)) {
          window.location.href = `https://open.weixin.qq.com/connect/qrconnect?appid=${aidWeb}&redirect_uri=${encodeURIComponent(
            `https://wap.lihan.ltd/?sd_next=${encodeURIComponent(
              window.location.href
            )}`
          )}&response_type=code&scope=snsapi_login&state=a#wechat_redirect`
        } else {
          window.location.href = `https://open.weixin.qq.com/connect/qrconnect?appid=${aidWeb}&redirect_uri=${encodeURIComponent(
            window.location
          )}&response_type=code&scope=snsapi_login&state=a#wechat_redirect`
        }
      } else {
        Toast.info('请在微信中打开')
      }
      return
    }

    if (code) {
      this.wxCode = code
      if (this.props.kind === 0) {
        this.handleLoginWeixin()
      }
    }
  }

  handleLoginWeixin = async () => {
    const {dispatch, history, next, hostname} = this.props
    showLoading()
    try {
      // const code = this.refCode.value
      const params = {
        code: this.wxCode,
        kind: 1,
      }
      const res = await dispatch(loginWx(params))
      const {
        needBindPhone = false,
        thirdInfo: {openId = ''} = {},
      } = res.payload.data
      if (needBindPhone) {
        history.replace(
          `/login?kind=1&code=${
            this.wxCode
          }&openId=${openId}&next=${encodeURIComponent(next)}`
        )
      } else {
        if (next !== '') {
          /*
          history.replace(`${next}`)
          history.go(0)
          */
          openWindow({
            url: fullUrl(`${next}`),
            closeCurrent: false,
          })
          return
        }
        if (isSD(hostname)) {
          history.replace('/hybrid/lh/sz/home')
        } else {
          history.replace('/wallet')
        }
      }
    } catch (err) {
      Toast.info(err || '登录失败，请重试')
    } finally {
      hideLoading()
    }
  }

  startCounter = () => {
    this.setState({
      elapseSeconds: 0,
    })
    this.timer = setInterval(() => {
      this.setState(
        ({elapseSeconds}) => ({
          elapseSeconds: elapseSeconds + 1,
        }),
        () => {
          if (this.state.elapseSeconds > MAX_ELAPSE - 1) {
            clearInterval(this.timer)
            this.setState({
              elapseSeconds: -1,
            })
          }
        }
      )
    }, 1000)
  }

  render() {
    const {phone, elapseSeconds, code, region} = this.state
    const {userAgent, kind, hostname, next} = this.props

    if (isLH(userAgent, hostname)) {
      window.location.href = `https://app.lihan.ltd/index.php/user/login`
      return null
    } else if (isPW(userAgent)) {
      loginApp(null, ({mid, token}) => {
        // eslint-disable-next-line no-console
        console.log(mid, token)
        setAuthInfo({mid, token})
        if (next !== '') {
          openWindow({
            closeCurrent: true,
            url: fullUrl(`${next}`),
            fullscreen: false,
          })
          return
        }
        closeWindow()
      })
      return null
    }

    return (
      <div className="Login">
        {(isX(hostname) &&
          ENVIRONMENT === 'production' && (
            <Fragment />
            // <Fragment>
            //   <div className="Login__wx Login--x">
            //     <div
            //       className="Login__wx-wrap"
            //       onClick={() => this.getWxCode(true)}
            //       role="button"
            //       tabIndex="0"
            //     >
            //       <img src={imgShareWechat} alt="" />
            //       <p>微信登录</p>
            //     </div>
            //   </div>
            //   <div
            //     style={{position: 'absolute', top: '16px', right: '16px'}}
            //     onClick={() => {
            //       const {history} = this.props
            //       if (next !== '') {
            //         history.replace(`${next}`)
            //         history.go(0)
            //         return
            //       }
            //       closeWindow()
            //     }}
            //     role="button"
            //     tabIndex="0"
            //   >
            //     <img src={imgClose} alt="" style={{width: '15px'}} />
            //   </div>
            // </Fragment>
          )) || (
          <Fragment>
            <Helmet>
              <title>登录</title>
            </Helmet>
            <div className="Login__header">
              {(kind === 0 && (
                <div
                  className={cx('Login__logo', {
                    'Login__logo--sd': isSD(hostname),
                    'Login__logo--x': isX(hostname),
                  })}
                />
              )) || <div className="Login__title">绑定手机</div>}
            </div>
            <div className="Login__panel">
              <div className="Login__field">
                <Region
                  onChange={val => {
                    this.setState({
                      region: val,
                    })
                  }}
                  value={region}
                />
                <div className="Login__inputPhone">
                  <input
                    type="tel"
                    value={phone}
                    placeholder="请输入手机号"
                    onChange={this.onInputPhone}
                  />
                </div>
              </div>
              <div className="Login__field">
                <div className="Login__inputCode">
                  <input
                    ref={ele => {
                      this.refCode = ele
                    }}
                    placeholder="请输入验证码"
                    onChange={this.onInputCode}
                    value={code}
                    type="number"
                    autoComplete="one-time-code"
                    maxLength={codeLength}
                  />
                </div>
                {elapseSeconds < 0 && (
                  <div
                    className={cx('Login__btnGetCode')}
                    role="button"
                    tabIndex={0}
                    onClick={this.onGetVerifyCode}
                  >
                    获取验证码
                  </div>
                )}
                {elapseSeconds >= 0 && (
                  <div className="Login__elapse">
                    {`${MAX_ELAPSE - elapseSeconds}s后重新获取`}
                  </div>
                )}
              </div>
            </div>
            <div className="Login__footer">
              <div
                className={cx('Login__btnLogin')}
                role="button"
                tabIndex={0}
                onClick={this.onTryLogin}
              >
                登录
              </div>
            </div>
            {kind === 0 && (
              <div className="Login__wx">
                <div
                  className="Login__wx-wrap"
                  onClick={() => this.getWxCode(true)}
                  role="button"
                  tabIndex="0"
                >
                  <img src={imgShareWechat} alt="" />
                  <p>微信登录</p>
                </div>
              </div>
            )}
            <div
              style={{position: 'absolute', top: '16px', right: '16px'}}
              onClick={() => {
                if (kind !== 0) {
                  openWindow({
                    closeCurrent: true,
                    url: fullUrl('/login'),
                  })
                } else {
                  const {history} = this.props
                  if (next !== '') {
                    history.replace(`${next}`)
                    history.go(0)
                    return
                  }
                  closeWindow()
                }
              }}
              role="button"
              tabIndex="0"
            >
              <img src={imgClose} alt="" style={{width: '15px'}} />
            </div>
          </Fragment>
        )}
      </div>
    )
  }
}

Login.propTypes = {
  dispatch: PT.func.isRequired,
  next: PT.string.isRequired,
  userAgent: PT.string.isRequired,
  hostname: PT.string.isRequired,
  history: RRPT.history.isRequired,
  kind: PT.number,
}

Login.defaultProps = {
  kind: 0,
}

export default connect((state, props) => {
  const {next = '', kind = 0} = qs.parse(props.location.search) || {}
  const {
    env: {userAgent, hostname},
  } = state
  return {next, userAgent, hostname, kind: Number(kind)}
})(Login)
